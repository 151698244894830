<template>
  <div class="timetable-header">
    <div class="timetable-header__label"></div>
    <div class="timetable-header__stop-points">
      <div class="timetable-header__stop-point" v-for="item in data" :key="item">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  emits: ['update:skipTrackingpoint'],
  name: 'TimeTableHeader',
})
export default class TimeTableHeader extends Vue {
  @Prop({ type: Object }) readonly data?: Array<string>
}
</script>
