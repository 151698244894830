<template>
  <div class="page-container">
    <div class="page-container__content">
      <div class="page-header">
        <div class="page-header__page-title">
          <div class="page-header__text">
            <div class="page-header__main">
              {{ $t('timetable.state_operation_management') }}
            </div>
          </div>
          <div class="page-header__explanation-note">
            <ColorNoteItem :label="$t('Scheduled')" :state="stateScheduled" />
            <ColorNoteItem :label="$t('Acked')" :state="stateAcked" />
            <ColorNoteItem :label="$t('Delayed')" :state="stateDelayed" />
            <ColorNoteItem :label="$t('Tracked')" :state="stateTracked" />
          </div>
        </div>
        <div class="page-header__page-actions">
          <div>
            <el-button type="primary" icon="el-icon-plus" style="width: 174px" @click="onPickCsvFile">
              {{ $t('timetable.csv_upload') }}
            </el-button>
            <input
              type="file"
              style="display: none"
              ref="fileCsvInput"
              accept="text/csv"
              @change="onCsvFilePicked($event.target.files[0])"
            />
          </div>
        </div>
      </div>

      <div class="timetable-filter flex">
        <div class="flex">
          <el-date-picker
            format="YYYY/MM/DD"
            class="w-48"
            v-model="selectedDateObject"
            type="date"
            :clearable="false"
            placeholder="Pick a day"
          >
          </el-date-picker>
        </div>
        <template v-if="hasTimeTable">
          <div class="flex ml-8">
            <el-button @click="buttonDeleteClicked()" type="danger" icon="el-icon-minus">
              {{ $t('delete') }}
            </el-button>
          </div>
          <div class="flex ml-8">
            <el-button @click="onExportPDF()" type="primary" class="btn-export">
              {{ $t('export_pdf') }}
            </el-button>
          </div>
        </template>
      </div>
      <div class="page-body" v-loading="loading">
        <TimeTableTable
          :projectCode="timetable?.projectCode"
          :projectVersion="timetable?.projectVersion"
          :selectedDate="selectedDate"
          :schedules="timetable?.schedules"
          :maxStopPointNumber="maxStopPointNumber"
          :loading="loading"
          @update:timeTableTable="confirmAcknowledge"
        />
      </div>
    </div>
    <el-dialog :model-value="dialog.isVisible" @closed="clearDialogData" center :custom-class="'el-dialog--no-heading'">
      <p v-if="!!dialog.title">
        {{ $t(`${dialog.title}`) }}
      </p>
      <p class="el-dialog--hightlight-text" v-if="!!dialog.content">
        {{ $t(`${dialog.content}`) }}
      </p>
      <template #footer>
        <el-button type="danger" @click="confirmDialog" v-if="!!dialog.buttonContent">
          {{ $t(`${dialog.buttonContent}`) }}
        </el-button>
      </template>
    </el-dialog>
    <ToPDFRender
      v-if="isTriggerExportPDF"
      :selectedDate="selectedDate"
      :timetableData="timetable"
      @update:exportPDFDone="exportPDFDone"
    />
  </div>
</template>

<script lang="ts">
import ColorNoteItem from '@/components/timetable/ColorNoteItem.vue'
import TimeTableTable from '@/components/timetable/TimeTableTable.vue'
import ToPDFRender from '@/components/timetable/ToPDFRender.vue'
import {
  ACKNOWNLEDGE_TIMETABLE,
  DELETE_TIME_TABLE,
  IMPORT_TIMETABLE_CSV,
  LOAD_TIMETABLE,
  OPEN_DIALOG,
  UPDATE_TIMETABLE_TIME,
} from '@/store/actions'
import { TIMETABLE_DATETIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { convertStringToDate } from '@/utils/timeUtils'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import moment from 'moment-timezone'
import { ETimeTableState } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IDialogMessage, ITimeTable, ITimetableUpdateNode } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    ColorNoteItem,
    TimeTableTable,
    ToPDFRender,
  },
  directives: { maska },
  name: 'TimeTable',
})
export default class TimeTable extends Vue {
  loading = false
  uploadedProgress = 0

  selectedDateObject = new Date() as Date

  actionButton = ''
  acknowledgePayload = {} as ITimetableUpdateNode
  isTriggerExportPDF = false

  dialog: IDialogMessage = {
    isVisible: false,
    title: '',
    content: '',
    buttonContent: '',
  }

  get selectedDate() {
    return this.$store.state.timetable.selectedDate
  }

  get timetable(): ITimeTable {
    return this.$store.state.timetable.timetable
  }

  get stateAcked() {
    return ETimeTableState.ACKED
  }

  get stateTracked() {
    return ETimeTableState.TRACKED
  }

  get stateDelayed() {
    return ETimeTableState.DELAYED
  }

  get stateScheduled() {
    return ETimeTableState.SCHEDULED
  }

  get hasTimeTable() {
    return !isEmpty(this.timetable)
  }

  get maxStopPointNumber() {
    let number = 0
    if (this.timetable?.schedules) {
      Object.values(this.timetable.schedules).forEach((element) => {
        if (element.paths) {
          const numberItem = element.paths.length * 2
          if (number < numberItem) {
            number = numberItem
          }
        }
      })
    }
    return number
  }

  exportPDFDone() {
    this.isTriggerExportPDF = false
  }

  onExportPDF() {
    this.isTriggerExportPDF = true
  }

  @Watch('selectedDateObject')
  selectedDateChanged(value: Date) {
    const newTime = moment(value)
    const selectedDate = newTime.format(TIMETABLE_DATETIME.DATE_PARAM_FORMAT)
    this.reloadData(selectedDate)
  }

  onPickCsvFile() {
    this.$refs.fileCsvInput.click()
  }

  async onCsvFilePicked(firstCsvFile: Blob) {
    this.loading = true
    try {
      const newDate = await this.$store.dispatch(IMPORT_TIMETABLE_CSV, { file: firstCsvFile })
      this.$store.dispatch(OPEN_DIALOG, {
        message: this.$t('timetable.import_success'),
        needtranslation: true,
      })

      if (newDate) {
        this.selectedDateObject = convertStringToDate(newDate)
      }
    } catch (error) {
      if (error !== '2306') {
        errorHandler(error)
      }
    } finally {
      this.loading = false
    }

    this.$refs.fileCsvInput.value = null
  }

  async reloadData(selectedDate: string) {
    this.loading = true
    try {
      await this.$store.dispatch(LOAD_TIMETABLE, { date: selectedDate })
    } catch (error) {
      if (error !== '2306') {
        errorHandler(error)
      }
    } finally {
      this.loading = false
    }
  }

  confirmDialog() {
    try {
      switch (this.actionButton) {
        case 'delete':
          this.deleteTimeTable()
          break
        case 'updateStopPoint':
          this.onUpdateStopPoint(this.acknowledgePayload)
          break
        default:
          break
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      this.clearDialogData()
    }
  }

  async deleteTimeTable() {
    this.loading = true
    try {
      await this.$store.dispatch(DELETE_TIME_TABLE, {
        id: this.timetable.id,
        selectedDate: this.selectedDate,
      })
    } catch (error) {
      if (error !== '2306') {
        errorHandler(error)
      }
    } finally {
      this.loading = false
    }
  }

  confirmAcknowledge(payload: ITimetableUpdateNode): void {
    payload.TimetableId = this.timetable.id
    if (payload.ScheduledTime) {
      this.onUpdateStopPointTime(payload)
    } else {
      this.acknowledgePayload = cloneDeep(payload)

      this.actionButton = 'updateStopPoint'
      this.openDialog({
        title: 'timetable.acknowledge_stop_point_change_message',
        content: 'timetable.normalize_process_message',
        buttonContent: 'timetable.process',
      })
    }
  }

  async onUpdateStopPointTime(payload: ITimetableUpdateNode) {
    this.loading = true
    try {
      await this.$store.dispatch(UPDATE_TIMETABLE_TIME, {
        payload,
        selectedDate: this.selectedDate,
      })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  openDialog(dialogMessage: IDialogMessage) {
    this.dialog = {
      title: dialogMessage?.title ?? '',
      content: dialogMessage?.content ?? '',
      buttonContent: dialogMessage?.buttonContent ?? '',
      isVisible: true,
    }
  }

  clearDialogData() {
    this.actionButton = ''
    this.dialog = {
      title: '',
      content: '',
      buttonContent: '',
      isVisible: false,
    }
  }

  async onUpdateStopPoint(payload: ITimetableUpdateNode) {
    this.loading = true
    try {
      await this.$store.dispatch(ACKNOWNLEDGE_TIMETABLE, { payload, selectedDate: this.selectedDate })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  created() {
    this.selectedDateObject = convertStringToDate(this.selectedDate)
  }

  buttonDeleteClicked() {
    this.actionButton = 'delete'
    this.openDialog({
      content: 'timetable.timetable_confirm_delete',
      buttonContent: 'timetable.process',
    })
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
.timetable-filter:deep() {
  margin: 20px 0px;
  .el-input {
    width: 200px;
    font-size: 18px;
    &__prefix {
      left: auto;
      right: 20px;
    }
    &__inner {
      background-color: inherit;
      border-color: #979797;
    }
  }
}

.page-container {
  width: 100%;
  height: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    :deep() {
      .el-loading-mask {
        background: $body-bg;
      }
    }
  }

  .page-header {
    display: flex;

    &__page-title {
      flex-grow: 70;
      display: flex;
    }

    &__main {
      font-weight: bold;
      font-size: 18px;
      font-family: 'Noto Sans JP';
    }

    &__sub {
      color: #858789;
      font-weight: 400;
      font-size: 12px;
      margin-top: 5px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__explanation-note {
      display: flex;
      margin-left: 40px;
    }

    &__controller {
      flex-grow: 30;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .el-button {
        width: 120px;
        margin-left: 20px;
      }
    }
  }

  .page-body {
    width: 100%;
    overflow: auto;
    flex-grow: 1;
  }
}
</style>
