<template>
  <div class="stop-point">
    <StopPointRoute :routeWidth="routeWidthTypes.LONG" v-if="!isFirstNode" :state="node.departureStatus" />

    <StopPointTime
      :customClass="getClass(node.departureStatus)"
      :time="scheduledDepartureTime"
      :state="node.departureStatus"
      :pathType="timetableNodePath.departure"
      @update:pointTime="onUpdateStopPointState"
    />
  </div>
  <div class="stop-point">
    <StopPointRoute :routeWidth="routeWidthTypes.LONG" :state="node.arrivalStatus" />

    <StopPointTime
      :customClass="getClass(node.arrivalStatus)"
      :time="scheduledArrivalTime"
      :state="node.arrivalStatus"
      :pathType="timetableNodePath.arrival"
      @update:pointTime="onUpdateStopPointState"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import StopPointRoute from '@/components/timetable/StopPointRoute.vue'
import StopPointTime from '@/components/timetable/StopPointTime.vue'
import { ITimeTableNewSchedulePath, ITimetableUpdateNode } from 'smartbarcode-web-core/src/utils/types/index'
import { EPathType, ERouteWidth, ETimeTableState } from 'smartbarcode-web-core/src/utils/enums/index'
import { Emit, Prop } from 'vue-property-decorator'
import { getRelativeTime } from '@/utils/timeUtils'

@Options({
  emits: ['update:pointNode'],
  components: { StopPointRoute, StopPointTime },
  name: 'TimeTableNode',
})
export default class TimeTableNode extends Vue {
  @Prop({ type: Object }) readonly node!: ITimeTableNewSchedulePath
  @Prop({ type: String }) readonly selectedDate!: string
  @Prop({ type: Boolean }) readonly isFirstNode!: boolean

  get timetableNodePath() {
    return {
      [EPathType.DEPARTURE]: EPathType.DEPARTURE,
      [EPathType.ARRIVAL]: EPathType.ARRIVAL,
    }
  }

  get routeWidthTypes(): Record<string, string> {
    return ERouteWidth
  }

  get scheduledDepartureTime() {
    return getRelativeTime(this.node.scheduledDepartureTime, this.selectedDate)
  }

  get scheduledArrivalTime() {
    return getRelativeTime(this.node.scheduledArrivalTime, this.selectedDate)
  }

  colorClassBlockByState(state: string): string {
    const itemClass = 'time-table__item-color'
    switch (state) {
      case ETimeTableState.TRACKED:
        return `${itemClass}--gray`
      case ETimeTableState.DELAYED:
        return `${itemClass}--red`
      case ETimeTableState.ACKED:
        return `${itemClass}--yellow`
      default:
        return `${itemClass}--none`
    }
  }

  textColorClassByState(state: string): string {
    return state === ETimeTableState.TRACKED ? 'pure-white-text' : 'pure-black-text'
  }

  @Emit('update:pointNode')
  onUpdateStopPointState(payload: ITimetableUpdateNode) {
    payload.TrackPointOriginKey = String(this.node.trackPointOriginKey)
    payload.TrackPointDestinationKey = String(this.node.trackPointDestinationKey)
    return payload
  }

  getClass(state: string): string[] {
    return [this.colorClassBlockByState(state), this.textColorClassByState(state)]
  }
}
</script>
