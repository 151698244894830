<template>
  <div class="color-note-item">
    <StopPointRoute :routeWidth="routeWidthTypes.MEDIUM" :state="state" />
    <div class="color-note-item__label">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import { ERouteWidth } from 'smartbarcode-web-core/src/utils/enums/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import StopPointRoute from './StopPointRoute.vue'

@Options({
  components: { StopPointRoute },
  name: 'ColorNoteItem',
})
export default class ColorNoteItem extends Vue {
  @Prop({ type: String }) readonly label!: string
  @Prop({ type: String }) readonly state!: string

  get routeWidthTypes(): Record<string, string> {
    return ERouteWidth
  }
}
</script>
<style lang="scss" scoped>
.color-note-item {
  display: flex;
  align-items: center;

  &__label {
    padding: 0 20px 0 5px;
    font-size: 12px;
  }
}
</style>
