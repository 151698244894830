<template>
  <div class="timetable-record">
    <div class="timetable-record__label">
      <div class="timetable-record__round-label">
        Line
        <span class="timetable-record__round-label__index">{{ lineIndex }}</span>
      </div>
      <div class="timetable-record__qrcode cursor-pointer" @click="goToBarcodeDetail()">
        <img class="qrcode-item" :src="qrCodeBase64" />
        <!-- <qrcode-vue :value="getUrlQrcode" render-as="canvas" class="qrcode-item" :size="45" /> -->
      </div>
    </div>
    <div class="timetable-record__round-trip">
      <div>
        <div class="timetable-record__timeline">
          <template v-for="(node, idx) in data.paths" :key="idx">
            <TimeTableNode
              :isFirstNode="idx === 0"
              :node="node"
              :selectedDate="selectedDate"
              @update:pointNode="onUpdatePointLine"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ITimeTableNewSchedule, ITimetableUpdateNode } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import TimeTableNode from '@/components/timetable/TimeTableNode.vue'
import QRious from 'qrious'

@Options({
  emits: ['update:onUpdatePointLine'],
  components: { TimeTableNode },
  name: 'TimeTableLine',
})
export default class TimeTableLine extends Vue {
  @Prop({ type: String }) readonly projectCode!: string
  @Prop({ type: Number }) readonly projectVersion!: number
  @Prop({ type: Object }) readonly data!: ITimeTableNewSchedule
  @Prop({ type: String }) readonly lineIndex!: string
  @Prop({ type: String }) readonly selectedDate!: string

  qrCodeBase64 = ''

  get recordData() {
    return this.data
  }

  @Emit('update:onUpdatePointLine')
  onUpdatePointLine(payload: ITimetableUpdateNode) {
    payload.LineNumber = this.lineIndex
    return payload
  }

  @Watch('data', { deep: true })
  async generateQrcode() {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    const url = `${appHost}/${this.projectCode}@${this.projectVersion}/qrcode/${this.data.barcodeId}`
    const qr = new QRious()
    qr.set({
      level: 'L',
      size: 45,
      value: url,
    })
    this.qrCodeBase64 = await qr.toDataURL('image/jpeg')
  }

  created() {
    this.generateQrcode()
  }

  goToBarcodeDetail() {
    this.$router.push({
      name: 'barcodeView',
      params: {
        id: this.data.barcodeId,
      },
    })
  }
}
</script>
