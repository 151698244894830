
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  emits: ['update:skipTrackingpoint'],
  name: 'TimeTableHeader',
})
export default class TimeTableHeader extends Vue {
  @Prop({ type: Object }) readonly data?: Array<string>
}
