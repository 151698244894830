
import { ERouteWidth } from 'smartbarcode-web-core/src/utils/enums/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import StopPointRoute from './StopPointRoute.vue'

@Options({
  components: { StopPointRoute },
  name: 'ColorNoteItem',
})
export default class ColorNoteItem extends Vue {
  @Prop({ type: String }) readonly label!: string
  @Prop({ type: String }) readonly state!: string

  get routeWidthTypes(): Record<string, string> {
    return ERouteWidth
  }
}
