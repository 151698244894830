<template>
  <div class="pdf-export-template" v-loading.fullscreen="localLoading">
    <vue3-simple-html2pdf ref="vue3SimpleHtml2pdf" :options="pdfOptions" :filename="exportFilename">
      <template v-for="(page, index) in timetableSchedules" :key="index">
        <span class="html2pdf__page-number"
          ><i>page {{ index }}</i></span
        >
        <div class="pdf-export-page">
          <h1>{{ $t('timetable_pdf.operation_table') }}</h1>

          <table class="pdf-export-page__outline-table">
            <tr>
              <td>
                <span> {{ $t('timetable_pdf.timetable_date') }}: </span>
                <span> {{ timetableData.date }} </span>
              </td>
              <td class="pdf-export-page__has-text-right">
                <span>{{ $t('timetable_pdf.issue_company') }}: </span>
                <span> SLOC </span>
              </td>
            </tr>
            <tr>
              <td>
                <span> {{ $t('timetable_pdf.vehicle_id') }}: </span>
                <span>
                  {{ extractVehicleIds(page.paths) }}
                </span>
              </td>
              <td class="pdf-export-page__has-text-right">
                <span> {{ $t('timetable_pdf.date_issued') }}: </span>
                <span>
                  {{ todayFormated }}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <span> {{ $t('timetable_pdf.line_no') }}: </span>
                <span>
                  {{ index }}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <span> {{ $t('timetable_pdf.timetable_code') }}: </span>
                <span>
                  {{ page.scheduleCode }}
                </span>
              </td>
            </tr>
          </table>

          <!-- Data table 1 -->
          <h2>{{ $t('timetable_pdf.routes') }}</h2>
          <table class="pdf-export-page__data-table">
            <tr>
              <th>{{ $t('timetable_pdf.time') }}</th>
              <th>{{ $t('tracking_point') }}</th>
              <th>{{ $t('timetable_pdf.shipping_company') }}</th>
              <th>{{ $t('timetable_pdf.container_storage') }}</th>
            </tr>
            <template v-for="(path, idx) in page.paths" :key="idx">
              <tr>
                <td>
                  {{ toLocalTime(path.scheduledDepartureTime) }}
                </td>
                <td>{{ path.trackPointOriginName }}</td>
                <td>{{ path.operatingCompanyName }}</td>
                <td>
                  {{ path.originContainerLocationCode }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ toLocalTime(path.scheduledArrivalTime) }}
                </td>
                <td>{{ path.trackPointDestinationName }}</td>
                <td>{{ path.operatingCompanyName }}</td>
                <td>
                  {{ path.destinationContainerLocationCode }}
                </td>
              </tr>
            </template>
          </table>

          <div class="pdf-export-page__barcode-image">
            <img :src="getQRCodeImage(page.barcodeId)" class="pdf-export-page__qr-code" />
          </div>

          <div class="pdf-export-page__node-text">
            {{ $t('timetable_pdf.about_the_procedure') }}
          </div>
        </div>
        <div class="html2pdf__page-break" style="margin-top: 10px"></div>
      </template>
    </vue3-simple-html2pdf>
  </div>
</template>

<script lang="ts">
import { ITimeTable, ITimeTableNewSchedulePath } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import QRCode from 'qrcode'
import moment from 'moment-timezone'
import { Prop, Watch } from 'vue-property-decorator'
import { displayMomentAtDate, displayMomentAtParam, displayRelativeTime } from '@/utils/timeUtils'

@Options({
  emits: ['update:exportPDFDone'],
  name: 'ToPDFRender',
})
export default class ToPDFRender extends Vue {
  @Prop({ type: String }) readonly selectedDate?: string
  @Prop({ type: Object }) readonly timetableData!: ITimeTable

  pdfOptions = {
    margin: 5,
    image: {
      type: 'jpeg',
      quality: 1,
    },
    html2canvas: { scale: 1 },
    jsPDF: {
      unit: 'mm',
      format: 'a4',
      orientation: 'p',
    },
  }

  barcodeImages: Record<string, string> = {}
  localLoading = true

  get timetableSchedules() {
    return this.timetableData.schedules
  }

  extractVehicleIds(paths: ITimeTableNewSchedulePath[]): string {
    const seen = [] as number[]
    paths.forEach((p) => {
      if (!seen.includes(p.vehicleId)) seen.push(p.vehicleId)
    })
    return seen.join(', ')
  }

  get todayFormated() {
    return displayMomentAtDate(moment())
  }

  get exportFilename() {
    return `timetable-${displayMomentAtParam(moment())}.pdf`
  }

  @Watch('localLoading')
  onExportReady() {
    this.exportPDF()
  }

  toLocalTime(datetime: string) {
    return displayRelativeTime(datetime, moment(this.selectedDate))
  }

  getPageNumber(index: string) {
    return parseInt(index, 10) + 1
  }

  async makeBarcodeImages() {
    for (const page of Object.values(this.timetableSchedules)) {
      this.barcodeImages[page.barcodeId] = await this.generateQRCodeImage(
        this.timetableData.projectCode,
        page.barcodeId
      )
    }
  }

  getQRCodeImage(barcodeId: string) {
    return this.barcodeImages[barcodeId]
  }

  async generateQRCodeImage(projectCode: string, barcodeId: string) {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    const url = `${appHost}/${projectCode}/qrcode/${barcodeId}`

    return QRCode.toDataURL(url)
  }

  async loadTimetableSchedule() {
    await this.makeBarcodeImages()
    this.localLoading = false
  }

  exportPDF() {
    this.$refs.vue3SimpleHtml2pdf.download()
    this.$emit('update:exportPDFDone')
  }

  async mounted() {
    await this.loadTimetableSchedule()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.pdf-export-template {
  display: none;
}

.html2pdf__page-number {
  position: relative;
  top: 0px !important;
  left: 35px !important;
  margin: auto;
  color: grey;
  font-size: 12px;
  font-style: italic;
  text-align: left !important;
}

.pdf-export-page {
  position: relative;
  border: 1px solid black;
  margin: 10px 30px 0;
  padding: 30px 35px;

  .red-text {
    color: red;
  }

  &__has-text-right {
    text-align: right;
  }

  h1 {
    font-size: 35px;
    font-weight: bold;
    margin: 0 0 25px 0;
    padding: 0;
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 30px 0 10px;
  }

  table {
    width: 100%;
  }

  &__outline-table {
    border: none;
    td {
      padding: 4px 0;
    }
  }

  &__data-table {
    font-size: 14px;
    text-align: center;
    border-collapse: collapse;

    th {
      background: $light-grey;
      font-weight: bold;
    }
    td,
    th {
      padding: 4px 0 8px 0;
      text-align: center;
      border: 1px solid $light-grey-portal;
    }
  }

  &__barcode-image {
    margin-top: 20px;
  }

  &__node-text {
    height: 200px;
    padding: 15px;
    margin-top: 40px;
    border: 1px solid $light-grey-portal;
  }

  &__qr-code {
    width: 100px;
    height: 100px;
  }
}
</style>
