
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import TimeTableHeader from '@/components/timetable/TimeTableHeader.vue'
import TimeTableLine from '@/components/timetable/TimeTableLine.vue'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IRouteName, ITimeTableNewSchedule, ITimetableUpdateNode } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Options({
  emits: ['update:timeTableTable'],
  components: { TimeTableHeader, TimeTableLine, ItemNotFound },
  name: 'TimeTableTable',
})
export default class TimeTableTable extends Vue {
  @Prop({ type: String }) readonly projectCode!: string
  @Prop({ type: Number }) readonly projectVersion!: number
  @Prop({ type: Object }) readonly schedules?: Record<number, ITimeTableNewSchedule>
  @Prop({ type: String }) readonly selectedDate?: string
  @Prop({ type: Number }) readonly maxStopPointNumber!: number

  get isTimetableEmpty() {
    return isEmpty(this.schedules)
  }

  get timeTableStyle() {
    return {
      width: `${213 + this.maxStopPointNumber * 115 + (this.maxStopPointNumber - 1) * 70 + 48}px`,
    }
  }

  get routeName(): IRouteName {
    return this.recordData?.routeName ?? ({} as IRouteName)
  }

  getheaderData(data: ITimeTableNewSchedule): string[] {
    const trackPointNames = [] as Array<string>
    data.paths.forEach((element) => {
      if (element.trackPointOriginName) trackPointNames.push(element.trackPointOriginName)
      if (element.trackPointDestinationName) trackPointNames.push(element.trackPointDestinationName)
    })

    return trackPointNames
  }

  @Emit('update:timeTableTable')
  onUpdatePointState(payload: ITimetableUpdateNode) {
    return payload
  }
}
