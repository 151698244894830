
import { ITimeTable, ITimeTableNewSchedulePath } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import QRCode from 'qrcode'
import moment from 'moment-timezone'
import { Prop, Watch } from 'vue-property-decorator'
import { displayMomentAtDate, displayMomentAtParam, displayRelativeTime } from '@/utils/timeUtils'

@Options({
  emits: ['update:exportPDFDone'],
  name: 'ToPDFRender',
})
export default class ToPDFRender extends Vue {
  @Prop({ type: String }) readonly selectedDate?: string
  @Prop({ type: Object }) readonly timetableData!: ITimeTable

  pdfOptions = {
    margin: 5,
    image: {
      type: 'jpeg',
      quality: 1,
    },
    html2canvas: { scale: 1 },
    jsPDF: {
      unit: 'mm',
      format: 'a4',
      orientation: 'p',
    },
  }

  barcodeImages: Record<string, string> = {}
  localLoading = true

  get timetableSchedules() {
    return this.timetableData.schedules
  }

  extractVehicleIds(paths: ITimeTableNewSchedulePath[]): string {
    const seen = [] as number[]
    paths.forEach((p) => {
      if (!seen.includes(p.vehicleId)) seen.push(p.vehicleId)
    })
    return seen.join(', ')
  }

  get todayFormated() {
    return displayMomentAtDate(moment())
  }

  get exportFilename() {
    return `timetable-${displayMomentAtParam(moment())}.pdf`
  }

  @Watch('localLoading')
  onExportReady() {
    this.exportPDF()
  }

  toLocalTime(datetime: string) {
    return displayRelativeTime(datetime, moment(this.selectedDate))
  }

  getPageNumber(index: string) {
    return parseInt(index, 10) + 1
  }

  async makeBarcodeImages() {
    for (const page of Object.values(this.timetableSchedules)) {
      this.barcodeImages[page.barcodeId] = await this.generateQRCodeImage(
        this.timetableData.projectCode,
        page.barcodeId
      )
    }
  }

  getQRCodeImage(barcodeId: string) {
    return this.barcodeImages[barcodeId]
  }

  async generateQRCodeImage(projectCode: string, barcodeId: string) {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    const url = `${appHost}/${projectCode}/qrcode/${barcodeId}`

    return QRCode.toDataURL(url)
  }

  async loadTimetableSchedule() {
    await this.makeBarcodeImages()
    this.localLoading = false
  }

  exportPDF() {
    this.$refs.vue3SimpleHtml2pdf.download()
    this.$emit('update:exportPDFDone')
  }

  async mounted() {
    await this.loadTimetableSchedule()
  }
}
