<template style="position: relative;">
  <div class="route-item" v-if="state">
    <div class="route-item__route" :class="routeClasses"></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ERouteWidth, ETimeTableState } from 'smartbarcode-web-core/src/utils/enums/index'
@Options({
  name: 'StopPointRoute',
})
export default class StopPointRoute extends Vue {
  @Prop({ type: String }) readonly routeWidth?: string
  @Prop({ type: String }) readonly state?: ETimeTableState

  get routeClasses() {
    const itemClass = 'route-item__route'
    let routeWidth = ''
    if (ERouteWidth.SHORT === this.routeWidth) {
      routeWidth = `${itemClass}--short`
    } else if (ERouteWidth.MEDIUM === this.routeWidth) {
      routeWidth = `${itemClass}--medium`
    } else {
      routeWidth = `${itemClass}--long`
    }

    const routeColor = this.colorClassLineByState(this.state ?? ETimeTableState.NONE)

    return [routeWidth, routeColor]
  }

  colorClassLineByState(state: ETimeTableState): string {
    const itemClass = 'time-table__item-color'
    switch (state) {
      case ETimeTableState.TRACKED:
        return `${itemClass}--gray`
      case ETimeTableState.DELAYED:
        return `${itemClass}--red`
      case ETimeTableState.ACKED:
        return `${itemClass}--yellow`
      default:
        return `${itemClass}--none`
    }
  }
}
</script>
