
import { ITimetableUpdateNode } from 'smartbarcode-web-core/src/utils/types/index'
import { ETimeTableState } from 'smartbarcode-web-core/src/utils/enums/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import IconClose from '@/components/svg/IconClose.vue'
import IconCheck from '@/components/svg/IconCheck.vue'
import { TIMETABLE_IS_EDIT_TIME } from '@/store/actions'
import { getCurrentUtcOffset, isRelativeTimeFormat } from '@/utils/timeUtils'

@Options({
  directives: { maska },
  components: {
    IconClose,
    IconCheck,
  },
  emits: ['update:pointTime'],
  name: 'StopPointTime',
})
export default class StopPointTime extends Vue {
  isEdit = false
  mask = this.timeMask
  error = false
  timeValue = '00:00'

  @Prop({ type: Array }) readonly customClass?: string[]
  @Prop({ type: String }) readonly time?: string
  @Prop({ type: String }) readonly pathType?: string
  @Prop({ type: String }) readonly state?: string

  get canEditStatus(): boolean {
    return this.state === ETimeTableState.DELAYED
  }

  get isOtherEditing(): boolean {
    return this.$store.state.timetable.isEditNode
  }

  created() {
    this.timeValue = this.time ?? '00:00'
  }

  onClickedEdit() {
    if (!this.isOtherEditing) {
      this.setEditing()
    }
  }

  onClickedChangeStatus() {
    const payload = {
      NodeType: this.pathType,
      Ack: true,
    } as ITimetableUpdateNode

    this.$emit('update:pointTime', payload)
  }

  submitEdit() {
    if (this.error === false) {
      const payload = {
        NodeType: this.pathType,
        ScheduledTime: this.timeValue.replaceAll(':', ''),
        utcOffset: getCurrentUtcOffset(),
      } as ITimetableUpdateNode

      this.$emit('update:pointTime', payload)
      this.closeEdit()
    }
  }

  setEditing() {
    this.isEdit = true
    this.$store.commit(TIMETABLE_IS_EDIT_TIME, true)
  }

  closeEdit() {
    this.isEdit = false
    this.$store.commit(TIMETABLE_IS_EDIT_TIME, false)
  }

  changeTime() {
    this.error = !isRelativeTimeFormat(this.timeValue)

    return !this.error
  }
}
